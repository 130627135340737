<template>
  <div class="web-content">
    <Search
      :termlist="termlist"
      :classlist="classlist"
      ref="search"
      :status="true"
      @handleQuery="handleQuery"
    />
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>违纪记录</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right">
        <el-button type="primary" plain
          ><download-excel
            :data="tableData"
            :fields="fields"
            name="违纪记录.xls"
          >
            导出
          </download-excel></el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="tableHeaderColor"
      :cell-style="cellStyleFun"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="userName" label="学生姓名"> </el-table-column>
      <el-table-column prop="studentCode" label="学籍号" width="180">
      </el-table-column>
      <el-table-column prop="className" label="班级" width="120">
      </el-table-column>
      <el-table-column prop="breakContext" label="违纪内容" width="180">
      </el-table-column>
      <el-table-column prop="breakScore" label="扣分值" width="120">
      </el-table-column>
      <el-table-column prop="createTime" label="发布时间" width="120">
      </el-table-column>
      <el-table-column prop="breakStatus" label="状态" width="120">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button @click="handleDetail(scope.row)" type="text" size="small"
            >查看详情</el-button
          >
          <el-button
            v-if="isTeacher == scope.row.createRole && scope.row.breakStatus == '存在'"
            @click="handleRevoke(scope.row)"
            type="text"
            size="small"
            >撤销</el-button
          >
          <el-button
            v-if="isTeacher == scope.row.createRole && scope.row.breakStatus == '撤销'"
            @click="handleRecovery(scope.row)"
            type="text"
            size="small"
            >恢复</el-button
          >
          <el-button v-if="isTeacher == scope.row.createRole" @click="handleRemove(scope.row)" type="text" size="small"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 查看详情 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" center>
      <el-form
        ref="form"
        :model="form"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        "
      >
        <el-form-item style="border-bottom: 1px dashed #e1e3e9">
          <span class="sname">{{ form.userName }}</span>
          <span class="snumb">学籍号：{{ form.studentCode }}</span>
        </el-form-item>
        <el-form-item label="违纪内容：">
          <span class="scontent">{{ form.breakContext }}</span>
        </el-form-item>
        <el-form-item label="扣分值：">
          <span class="scontent">{{ form.breakScore }}</span>
        </el-form-item>
        <el-form-item label="发布时间：">
          <span class="scontent">{{ form.createTime }}</span>
        </el-form-item>
        <el-form-item label="状态：" style="border-bottom: 1px dashed #e1e3e9">
          <span style="color: red">{{ form.breakStatus }}</span>
        </el-form-item>
        <el-form-item label="所在学期：">
          <span class="scontent">{{ queryParams.termName }}</span>
        </el-form-item>
        <!-- <el-form-item label="所在学校：">
          <span class="scontent">信德中学</span>
        </el-form-item> -->
        <el-form-item label="佐证材料：">
          <template v-for="(item, index) in form.proofFile">
            <el-image
              v-if="item.includes('.jpg') || item.includes('.png')"
              fit="contain"            
              :key="index"
              :src="imgSrc + item"
            ></el-image>
            <a target="_blank" v-else :href="imgSrc + item" :key="index" >附件{{index}}</a>
          </template>
        </el-form-item>
        <el-form-item label="备注说明：" >
          <span class="scontent">{{ form.bz }}</span>
        </el-form-item>
        <el-form-item label="撤销原因：" v-if="form.breakStatus == '撤销'">
          <span class="scontent">{{ form.revokeCause }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 撤销违纪 -->
    <el-dialog title="撤销原因" :visible.sync="openRevoke" width="600px" center>
      <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
        <el-form-item prop="revokeCause" label="请选择撤销原因">
          <br />
          <el-radio-group v-model="form.revokeCause">
            <el-radio label="经核实，违纪内容不存在"
              >经核实，违纪内容不存在</el-radio
            >
            <el-radio label="经后续观察，改过态度良好，改正效果明显"
              >经后续观察，改过态度良好，改正效果明显</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="撤销作用：" prop="termName">
          <span class="scontent">被扣除的分数将恢复</span>
        </el-form-item>
        <el-form-item prop="revokeRemark" label="备注说明">
          <el-input
            v-model="form.revokeRemark"
            type="textarea"
            autocomplete="off"
            :rows="3"
            placeholder="请输入备注说明，非必填"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmitRevoke">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {
  getWeiji,
  getWeijiDetail,
  removeWeiji,
  revokeWeiji,
  recoveryWeiji,
} from "@/api/teacher/quality/morality";
export default {
  data() {
    return {
      imgSrc: this.imageSrc(),
      tableData: [],
      title: "",
      open: false,
      openRevoke: false,
      form: {},
      rules: {
        revokeCause: [
          { required: true, message: "请选择撤销原因", trigger: "change" },
        ],
      },
      total: 0,
      queryParams: {
        pageSize: 100,
      },
      typeId: "301",
      termlist: [],
      classlist: [],
      fields: {
        学生姓名: "userName",
        学籍号: "studentCode",
        班级: "className",
        违纪内容: "breakContext",
        扣分值: "breakScore",
        发布时间: "createTime",
        状态: "breakStatus",
      },
      isTeacher: this.$store.getters.roles[0].roleKey
    };
  },
  components: {
    Search,
  },
  created() {
    // this.getFirst()
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetTermList").then((res) => {
        this.termlist = res.data;
      });
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.termName = this.$store.getters.queryForm.termName;
        this.queryParams.classId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init(
          this.queryParams.termName,
          this.queryParams.classId
        );
        this.getList();
      });
    },
    getList() {
      getWeiji(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.size;
      });
    },
    handleQuery(data) {
      this.queryParams.classId = data.deptId;
      this.queryParams.termName = data.term;
      this.queryParams.breakStatus = data.breakStatus;
      this.queryParams.searchStr = data.userName;
      this.getList();
    },
    handleDetail(row) {
      this.title = "违纪记录";
      this.open = true;
      this.isHonor = false;
      getWeijiDetail({ breakId: row.id }).then((res) => {
        this.form = res.data;
        if(this.form.proofFile) {
          this.form.proofFile = this.form.proofFile.split(",");
        }
      });
    },
    handleRemove(row) {
      this.$confirm(
        "删除该条记录后，学生对应被扣除的分数将恢复，确定要删除吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          removeWeiji({ breakId: row.id }).then((res) => {
            this.$message.success("删除成功");
            this.getList();
          });
        })
        .catch(() => {
          return false;
        });
    },
    handleRevoke(row) {
      this.openRevoke = true;
      this.form.breakId = row.id;
    },
    handleSubmitRevoke() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          revokeWeiji({
            breakId: this.form.breakId,
            revokeCause: this.form.revokeCause,
            revokeRemark: this.form.revokeRemark,
          }).then((res) => {
            this.$message.success("撤销成功");
            this.getList();
            this.openRevoke = false;
            this.resetForm("form");
          });
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.open = false;
      this.openRevoke = false;
      this.reset();
    },
    handleRecovery(row) {
      this.$confirm(
        "恢复该条记录后，学生对应的综素得分将被扣除，确定要恢复吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          recoveryWeiji({ breakId: row.id }).then((res) => {
            this.$message.success("恢复成功");
            this.getList();
          });
        })
        .catch(() => {
          return false;
        });
    },
    reset() {
      this.resetForm("form");
      this.form = {
        studentIds: [],
        userName: undefined,
        studentCode: undefined,
        honorName: undefined,
        indexRuleLevel: undefined,
        awardedUnit: undefined,
        termName: undefined,
        fileList: [],
        file: undefined,
        breakContext: undefined,
        breakScore: undefined,
        revokeCause: undefined,
      };
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
</style>
<style scoped>
.el-form-item {
  margin-bottom: 12px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.el-image {
  width: 100%;
}
</style>