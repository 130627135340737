<template>
  <div class="web-content">
    <Search
      :termlist="termlist"
      :classlist="classlist"
      ref="search"
      @handleQuery="handleQuery"
    />
    <!-- :termName='queryParams.termName' :classId='queryParams.classId' -->
    <el-row type="flex" justify="space-between">
      <el-col :span="6">
        <h3>思想品德</h3>
      </el-col>
      <el-col :span="18" class="btn" style="text-align: right">
        <el-button
          type="primary"
          style="display: inline-block; margin-right: 10px"
          plain
          ><download-excel
            :data="tableData"
            :fields="fields"
            name="思想品德成绩汇总.xls"
          >
            导出
          </download-excel></el-button
        >
        <el-button type="primary" plain @click="handleHonorGroup"
          >添加集体荣誉</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :header-cell-style="tableHeaderColor"
      :cell-style="cellStyleFun"
    >
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="userName" label="学生姓名"> </el-table-column>
      <el-table-column prop="studentCode" label="学籍号" width="180">
      </el-table-column>
      <el-table-column prop="className" label="班级" width="120">
      </el-table-column>
      <el-table-column prop="realyCount" label="写实记录（条）" width="120">
      </el-table-column>
      <el-table-column prop="honorCount" label="荣誉记录（条）" width="120">
      </el-table-column>
      <el-table-column prop="breakCount" label="违纪记录（条）" width="120">
      </el-table-column>
      <el-table-column prop="sumScore" label="本维度综素成绩（分）" width="180">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="200">
        <template slot-scope="scope">
          <el-button @click="handleDetail(scope.row)" type="text" size="small"
            >查看详情</el-button
          >
          <el-button @click="handleHonorAdd(scope.row)" type="text" size="small"
            >添加荣誉</el-button
          >
          <el-button @click="handleWeijiAdd(scope.row)" type="text" size="small"
            >添加违纪
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加荣誉 or 违纪 -->
    <el-dialog
      :title="title"
      :visible.sync="openAdd"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="600px"
      center
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        label-position="left"
        style="
          border-top: 1px solid #e1e3e9;
          border-bottom: 1px solid #e1e3e9;
          padding-top: 5px;
        "
      >
        <el-form-item
          v-if="!groupHonor"
          label-width="0"
          style="border-bottom: 1px dashed #e1e3e9"
        >
          <span class="sname">{{ form.userName }}</span>
          <span class="snumb">学籍号：{{ form.studentCode }}</span>
        </el-form-item>
        <!-- 违纪 -->
        <template v-if="!isHonor">
          <el-form-item label="违纪内容：" prop="breakContext">
            <el-input v-model="form.breakContext"></el-input>
          </el-form-item>
          <el-form-item label="扣分值：" prop="breakScore">
            <el-select
              style="width: 100%"
              v-model="form.breakScore"
              placeholder="请选择扣分值"
            >
              <el-option
                v-for="index in 10"
                :key="index"
                :label="index"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="所在学期：">
            <span class="scontent">2020-2021学年第一学期</span>
          </el-form-item>
          <el-form-item label="所在学校：">
            <span class="scontent">信德中学</span>
          </el-form-item> -->
        </template>

        <!-- 荣誉 -->
        <template v-if="isHonor">
          <el-form-item label="荣誉名称：" prop="honorName">
            <el-input v-model="form.honorName" placeholder="请输入荣誉名称"></el-input>
          </el-form-item>
          <el-form-item label="级别：" prop="indexRuleLevel">
            <el-select
              style="width: 100%"
              v-model="form.indexRuleLevel"
              placeholder="请选择级别"
            >
              <el-option
                v-for="(item, index) in honorTypelist"
                :key="index"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
		  <el-form-item label="获奖等级：" prop="resultLevel">
				<el-input placeholder="请输入获奖等级" v-model="form.resultLevel"></el-input>
		  </el-form-item>
          <el-form-item label="颁发单位：" prop="awardedUnit">
            <el-input v-model="form.awardedUnit" placeholder="请输入颁发单位"></el-input>
          </el-form-item>
        </template>

        <!-- 集体荣誉 start -->
        <el-form-item label="参与学生" prop="studentIds" v-if="groupHonor">
          <div class="student-box">
            <el-row
              type="flex"
              justify="space-around"
              style="border-bottom: 1px solid #e1e3e9"
            >
              <el-col style="padding-left: 15px">
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                  >全选</el-checkbox
                >
              </el-col>
              <el-col style="text-align: right; padding-right: 15px">
                已选<span style="color: #569df8">{{ students }}</span
                >人
              </el-col>
            </el-row>
            <ul style="padding: 0px 15px">
              <el-checkbox-group
                v-model="form.studentIds"
                @change="handleCheckedCitiesChange"
              >
                <p v-if="classStudent.length <= 0">暂无数据</p>
                <li v-for="(stu, sin) in classStudent" :key="sin">
                  <el-checkbox :label="stu.userId"
                    >{{ stu.userName }} {{ stu.loginName }}</el-checkbox
                  >
                </li>
              </el-checkbox-group>
            </ul>
          </div>
        </el-form-item>
        <!-- 集体荣誉 end -->
        <el-form-item label="所在学期：">
          <span class="scontent">{{ form.termName }}</span>
        </el-form-item>
        <!-- <el-form-item label="所在学校：">
            <span class="scontent">{{form.school}}</span>
          </el-form-item> -->
        <el-form-item label="佐证材料：">
          <el-upload
            action="nothing"
            accept=".jpg,.png,.doc,.docx,.xls,.xlsx,.pdf,.txt"
            :limit="3"
            :multiple="true"
            :file-list="form.fileList"
            :on-change="handleUpChange"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :auto-upload="false"
          >
            <el-button size="small" type="primary">添加附件</el-button>
            <div slot="tip" class="el-upload__tip">
              <!-- 只能上传jpg/png文件，且不超过1MB，最多三张 -->
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注说明：">
          <el-input
            v-model="form.bz"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {
  addHonor,
  moralityList,
  getHonorType,
  addWeiji,
} from "@/api/teacher/quality/morality";
import { getClassStudent } from "@/api/record/group/group";
export default {
  data() {
    return {
      tableData: [],
      classStudent: [],
      queryParams: {
        pageSize: 100,
      },
      total: 0,
      title: "",
      openAdd: false,
      isHonor: false,
      groupHonor: false,
      checkAll: false,
      isIndeterminate: false,
      form: {
        studentIds: [],
      },
      rules: {
        honorName: [
          { required: true, message: "请输入荣誉名称", trigger: "blur" },
        ],
        indexRuleLevel: [
          { required: true, message: "请选择荣誉级别", trigger: "change" },
        ],
        awardedUnit: [
          { required: true, message: "请输入颁发单位", trigger: "blur" },
        ],
        studentIds: [
          { required: true, message: "请选择参与学生", trigger: "change" },
        ],

        breakContext: [
          { required: true, message: "请输入违纪内容", trigger: "blur" },
        ],
        breakScore: [
          { required: true, message: "请选择扣分值", trigger: "change" },
        ],
      },
      students: 0,
      userIds: [],
      honorTypelist: [],
      typeId: "301",
      termlist: [],
      classlist: [],
      fields: {
        学生姓名: "userName",
        学籍号: "studentCode",
        班级: "className",
        "写实记录（条）": "realyCount",
        "荣誉记录（条）": "honorCount",
        "违纪记录（条）": "breakCount",
        "本维度综素成绩（分）": "sumScore",
      },
    };
  },
  components: {
    Search,
  },
  created() {
    // this.getFirst()
  },
  methods: {
    getFirst() {
      this.$store.dispatch("GetTermList").then((res) => {
        this.termlist = res.data;
      });
      this.$store.dispatch("GetClassList", this.typeId).then((res) => {
        this.classlist = res.data;
        this.queryParams.termName = this.$store.getters.queryForm.termName;
        this.queryParams.classId = this.$store.getters.queryForm.deptId;
        this.$refs.search.init(
          this.queryParams.termName,
          this.queryParams.classId
        );
        this.getList();
      });
    },
    getList() {
      moralityList(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.size;
      });
    },
    handleQuery(data) {
      this.queryParams.classId = data.deptId;
      this.queryParams.termName = data.term;
      this.queryParams.searchStr = data.userName;
      this.getList();
    },
    handleHonorGroup() {
      this.reset();
      this.title = "集体荣誉记录";
      this.openAdd = true;
      this.isHonor = true;
      this.groupHonor = true;
	  this.isIndeterminate = false
	  this.checkAll = false
	  this.students = 0
      this.form.termName = this.queryParams.termName;
      this.getStudent();
      this.getHonorTypeList();
    },
    handleSubmit() {
      if (this.form.file) {
        this.form.file = this.compressImg(this.form.file);
      }
      setTimeout(() => {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            if (this.isHonor && !this.groupHonor) {
              var formData = new FormData();
              if (this.form.file != undefined) {
                this.form.file.map((item) => {
                  formData.append("file", item);
                });
              }
              formData.append("userIds", this.form.userId);
              formData.append("honorName", this.form.honorName);
              formData.append("classId", this.queryParams.classId);
              formData.append("honorType", "0");
              formData.append("indexRuleLevel", this.form.indexRuleLevel);
              formData.append("awardedUnit", this.form.awardedUnit);
			  formData.append('resultLevel', this.form.resultLevel)
              formData.append("bz", this.form.bz);
              addHonor(formData).then((res) => {
                this.$message.success("添加成功");
                this.getList();
                this.openAdd = false;
                this.resetForm("form");
                this.reset();
              });
            } else if (this.isHonor && this.groupHonor) {
              var formData = new FormData();
              if (this.form.file != undefined) {
                this.form.file.map((item) => {
                  formData.append("file", item);
                });
              }
              formData.append("userIds", this.form.studentIds.join(","));
              formData.append("honorName", this.form.honorName);
              formData.append("classId", this.queryParams.classId);
              formData.append("honorType", "1");
              formData.append("indexRuleLevel", this.form.indexRuleLevel);
              formData.append("awardedUnit", this.form.awardedUnit);
			  formData.append('resultLevel', this.form.resultLevel)
              formData.append("bz", this.form.bz);
              addHonor(formData).then((res) => {
                this.$message.success("添加成功");
                this.getList();
                this.openAdd = false;
                this.resetForm("form");
                this.reset();
              });
            } else if (!this.isHonor && !this.groupHonor) {
              var formData = new FormData();
              if (this.form.file != undefined) {
                this.form.file.map((item) => {
                  formData.append("file", item);
                });
              }
              formData.append("userId", this.form.userId);
              formData.append("classId", this.queryParams.classId);
              formData.append("breakContext", this.form.breakContext);
              formData.append("breakScore", this.form.breakScore);
              formData.append("bz", this.form.bz);
              addWeiji(formData).then((res) => {
                this.$message.success("添加成功");
                this.getList();
                this.openAdd = false;
                this.resetForm("form");
              });
            }
          } else {
            return false;
          }
        });
      }, 1000);
    },
    cancel() {
      // this.resetForm('form')
      this.openAdd = false;
    },
    // 全选
    handleCheckAllChange(val) {
      const arr = [];
      this.form.studentName = "";
      if (val) {
        this.classStudent.map((item) => {
          arr.push(item.userId);
          this.form.studentName += item.userName + ",";
        });
        this.students = arr.length;
      } else {
        this.students = 0;
      }
      this.form.studentIds = val ? arr : [];
      this.isIndeterminate = false;
      // console.log(this.form.studentName, "全选");
    },
    // 单选
    handleCheckedCitiesChange(value) {
      this.form.studentName = "";
      this.students = value.length;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.classStudent.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.classStudent.length;
      value.map((check) => {
        this.classStudent.map((item) => {
          if (check == item.userId) {
            this.form.studentName += item.userName + ",";
          }
        });
      });
      // console.log(this.form.studentName, "单选");
    },
    handleDetail(row) {
      const params = {
        userId: row.userId,
        userName: row.userName,
        studentCode: row.studentCode,
        termName: this.queryParams.termName,
        className: row.className,
        classId: this.queryParams.classId,
      };
      this.$router.push({
        name: "studentResults",
        params: params,
      });
      sessionStorage["params"] = JSON.stringify(params);
    },
    handleHonorAdd(row) {
      this.reset();
      this.title = "荣誉记录";
      this.openAdd = true;
      this.isHonor = true;
      this.groupHonor = false;
      this.getHonorTypeList();
      this.form.userName = row.userName;
      this.form.userId = row.userId;
      this.form.studentCode = row.studentCode;
      this.form.termName = this.queryParams.termName;
    },
    handleWeijiAdd(row) {
      this.reset();
      this.title = "违纪记录";
      this.openAdd = true;
      this.isHonor = false;
      this.groupHonor = false;
      this.form.userName = row.userName;
      this.form.userId = row.userId;
      this.form.studentCode = row.studentCode;
      this.form.termName = this.queryParams.termName;
    },
    reset() {
      this.form = {
        studentIds: [],
        userName: '',
        studentCode: '',
        honorName: '',
        indexRuleLevel: '',
        awardedUnit: '',
        termName: '',
        fileList: [],
        file: null,
        breakContext: '',
        breakScore: '',
        bz: '',
      };
      this.resetForm("form");
    },
    // 上传图片
    handleUpChange(file, fileList) {
      var _this = this;
      _this.form.file = fileList;
      if (file.size / 1024 > 5 * 1024) {
        this.$message.warning("文件大小不得超过5MB");
        this.handleRemove(file);
      }
      // console.log(_this.form.file,'file');
    },
    handleRemove(file, fileList) {
      this.form.file.forEach((item, index) => {
        if (item.name === file.raw.name) {
          this.form.file.splice(index, 1);
        }
      });
    },
    handleExceed() {
      this.$message.warning("最多只能上传三个文件");
    },
    // 获取荣誉级别
    getHonorTypeList() {
      if (this.groupHonor) {
        getHonorType({ type: "collectHonorlevel" }).then((res) => {
          this.honorTypelist = res.data;
        });
      } else {
        getHonorType({ type: "personHonorlevel" }).then((res) => {
          this.honorTypelist = res.data;
        });
      }
    },
    // 获取学生列表
    getStudent() {
      getClassStudent({ deptId: this.queryParams.classId }).then((res) => {
        this.classStudent = res.data;
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
.web-content {
  width: 100%;
  .student-box {
    max-height: 343px;
    background: #f9fafc;
    border: 1px solid #e1e3e9;
    border-radius: 4px;
    overflow: auto;
  }
}
</style>
<style scoped>
.el-form-item {
  margin-bottom: 16px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
</style>