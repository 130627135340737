<template>
  <div class="container">
    <div class="tabs-nav">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="成绩汇总" name="0">
          <Summary ref="summary" />
        </el-tab-pane>
        <el-tab-pane label="荣誉记录" name="1">
          <Honor ref="honor" />
        </el-tab-pane>
        <el-tab-pane label="违纪记录" name="2">
          <Disciolinary ref="disciolinary" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Summary from './component/resultsSummary'
import Honor from './component/honorRecord'
import Disciolinary from './component/disciplinaryRecord'
export default {
  data() {
    return {
      activeName: "0",
    };
  },
  components: {
    Summary,
    Honor,
    Disciolinary
  },
  created() {
    this.handleClick()
  },
  methods: {
    handleClick(tab, event) {
      this.$nextTick(()=>{
        switch(this.activeName) {
          case '0': this.$refs.summary.getFirst();break;
          case '1': this.$refs.honor.getFirst();break;
          case '2': this.$refs.disciolinary.getFirst();break;
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .tabs-nav {
    width: 100%;
    padding: 15px 16px 30px;
    background: #fff;
    border-radius: 4px 4px 0px 0px;
    box-sizing: border-box;
  }
}
</style>
<style scoped>
.el-tabs--top >>> .el-tabs__item.is-top:nth-child(2){
  padding-left: 20px;
}
</style>